import Base from './Base';

export interface SettingBannerWideItemAttrs {
  type: 'IMAGE' | 'VIDEO';
  image?: string;
  imagePortrait?: string;
  video?: string;
  videoPortrait?: string;
  caption?: string;
  button?: string;
  url?: string;
}

export default class SettingBannerWideItem extends Base<SettingBannerWideItemAttrs> {
  get videoURL() {
    return this.data.video && fileUtil.getURL(this.data.video);
  }

  get videoPortraitURL() {
    const video = this.data.videoPortrait || this.data.video;
    return video && fileUtil.getURL(video);
  }

  getThumbnailURL(width: number, height?: number) {
    return (
      this.data.image &&
      imageUtil.getThumbnailURL(this.data.image, width, height)
    );
  }

  getPortraitThumbnailURL(width: number, height?: number) {
    const image = this.data.imagePortrait || this.data.image;
    return image && imageUtil.getThumbnailURL(image, width, height);
  }
}
